import './App.css';
// import Home from './Home';
import HomePage from './Home';
import PrivacyAndTerms from './PrivacyAndTerms';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

function App() {

  // Here is our password authentication since website cannot be public (until we are 506(c) fund)
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    if (password === '506(b)') {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  if (!isAuthenticated) {
    return (
      <div style={{width: '100%', paddingTop: '100px', textAlign: 'center'}}>
        <form onSubmit={handlePasswordSubmit}>
          <label>
            Password: <input type="password" name="password" />
          </label>
          <button type="submit">Enter</button>
        </form>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-and-terms" element={<PrivacyAndTerms />} />
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
